<template>
  <ClientsList />
</template>

<script>
  export default {
    components: {
      ClientsList: () => import('@/components/people/clients/ClientsList')
    }
  }
</script>